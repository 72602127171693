import React, { useCallback, useMemo, useState } from "react";

import { css, Global, keyframes, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";

import colors, { white } from "../../../../utils/colors";
import { FooterContainer } from "../../Footer";
import Icon from "../../icons/Icon";
import useStore from "../store";
import shapeBlue from "./shape-mobile-nav-blue.svg";
import shapeGrey from "./shape-mobile-nav-grey.svg";
import shapeRed from "./shape-mobile-nav-red.svg";

const closedShapeButtonHeight = "45px";

const ClickableCorner = styled.button`
    all: unset;
    position: absolute;
    z-index: 101;
    width: 60px;
    height: 100%;
    cursor: pointer;

    &.left {
        left: 0;
        border-top-right-radius: 15px;
    }

    &.right {
        right: 0;
        border-top-left-radius: 15px;
    }
`;

const Close = styled.a`
    position: absolute;
    top: 5px;
    right: 140px;
    z-index: 100;
    width: 20px;
    height: 20px;
    padding: 5px;
    font-size: 16px;
    color: white;
    text-decoration: none;

    .left & {
        right: 180px;
    }

    &:hover {
        color: white;
    }
`;

const Content = styled.div`
    width: 100%;
`;

const IconContainer = styled.div`
    position: absolute;

    .left & {
        bottom: 10px;
        left: 10px;
    }

    .right & {
        right: 10px;
        bottom: 10px;
    }
`;

const ShapeButtonMobile = ({
    name,
    children,
    color,
    offsetLeft,
    position = "left",
    iconName,
    ...props
}) => {
    const theme = useTheme();
    const [hasInteractedWithComponent, setHasInteractedWithComponent] =
        useState(false);

    const { open, setOpen, close } = useStore();

    const closeStopBubbling = useCallback(
        (e) => {
            e.stopPropagation();
            close();
        },
        [close]
    );

    const isOpen = useMemo(() => open === name, [open, name]);

    const shapeColors = {
        red: shapeRed,
        blue: shapeBlue,
    };

    const leftButtonKeyframeOpen = keyframes`
        0% {
            opacity: 1;
            transform: translate(0, 0);
        }
        50% {
            transform: translate(470px, -150px);
        }
        60% {
            opacity: 0.5;
        }
        100% {
            transform: translate(470px, -290px);
            opacity: 1;
        }
    `;

    const leftButtonKeyframeClose = keyframes`
        0% {
            transform: translate(490px, -290px);
            opacity: 1;
        }
        50% {
            transform: translate(490px, -150px);
            z-index: 100;
        }
        60% {
            opacity: 0.5;
        }
        100% {
            transform: translate(0, 0);
            z-index: 100;
            opacity: 1;
        }
    `;

    const leftButtonKeyframeOpenTablet = keyframes`
        0% {
            opacity: 1;
            transform: translate(0, 0);
        }
        50% {
            transform: translate(470px, -100px);
        }
        60% {
            opacity: 0.5;
        }
        100% {
            transform: translate(470px, -200px);
            opacity: 1;
        }
    `;

    const leftButtonKeyframeCloseTablet = keyframes`
        0% {
            transform: translate(490px, -200px);
            opacity: 1;
        }
        50% {
            transform: translate(490px, -100px);
            z-index: 100;
        }
        60% {
            opacity: 0.5;
        }
        100% {
            transform: translate(0, 0);
            z-index: 100;
            opacity: 1;
        }
    `;

    const rightButtonKeyframeOpen = keyframes`
        0% {
            opacity: 1;
            transform: translate(0, 0);
        }
        50% {
            transform: translate(-280px, -150px);
        }
        60% {
            opacity: 0.5;
        }
        100% {
            transform: translate(-280px, -290px);
            opacity: 1;
        }
    `;

    const rightButtonKeyframeClose = keyframes`
        0% {
            transform: translate(-270px, -290px);
            opacity: 1;
        }
        50% {
            transform: translate(-270px, -150px);
            z-index: 100;
        }
        60% {
            opacity: 0.5;
        }
        100% {
            transform: translate(0, 0);
            z-index: 100;
            opacity: 1;
        }
    `;

    const rightButtonKeyframeOpenTablet = keyframes`
        0% {
            opacity: 1;
            transform: translate(0, 0);
        }
        50% {
            transform: translate(-280px, -100px);
        }
        60% {
            opacity: 0.5;
        }
        100% {
            transform: translate(-280px, -200px);
            opacity: 1;
        }
    `;

    const rightButtonKeyframeCloseTablet = keyframes`
        0% {
            transform: translate(-270px, -200px);
            opacity: 1;
        }
        50% {
            transform: translate(-270px, -100px);
            z-index: 100;
        }
        60% {
            opacity: 0.5;
        }
        100% {
            transform: translate(0, 0);
            z-index: 100;
            opacity: 1;
        }
    `;

    const button = css`
        * {
            box-sizing: border-box;
        }

        position: absolute;
        display: flex;
        justify-content: center;
        width: 600px;
        height: 340px;
        filter: drop-shadow(0 2px 4px rgb(0 0 0 / 50%));
        background-repeat: no-repeat;
        background-size: contain;
        transition: all 0.3s ease-in;
        transform: translate(0, 0);

        &.closed {
            z-index: 100;
        }

        &.left {
            left: -540px;

            &.open {
                animation: linear 0.4s ${leftButtonKeyframeOpen} forwards;
                ${theme.breakpoints.from.small.css`
                    animation: linear 0.4s ${leftButtonKeyframeOpenTablet} forwards;
                `}
            }

            &.close {
                animation: linear 0.4s ${leftButtonKeyframeClose} forwards;
                ${theme.breakpoints.from.small.css`
                    animation: linear 0.4s ${leftButtonKeyframeCloseTablet} forwards;
                `}
            }
        }

        &.right {
            right: -400px;

            &.open {
                animation: linear 0.4s ${rightButtonKeyframeOpen} forwards;
                ${theme.breakpoints.from.small.css`
                    animation: linear 0.4s ${rightButtonKeyframeOpenTablet} forwards;
                `}
            }

            &.close {
                animation: linear 0.4s ${rightButtonKeyframeClose} forwards;
                ${theme.breakpoints.from.small.css`
                    animation: linear 0.4s ${rightButtonKeyframeCloseTablet} forwards;
                `}
            }
        }
    `;

    return (
        <>
            <Global
                styles={css`
                    ${FooterContainer} {
                        && {
                            padding-bottom: calc(
                                ${closedShapeButtonHeight} + 20px
                            );
                        }
                    }
                `}
            />

            {!isOpen && (
                <ClickableCorner
                    type="button"
                    className={position}
                    onClick={() => {
                        setHasInteractedWithComponent(true);
                        open !== name && setOpen(name);
                    }}
                >
                    {iconName && (
                        <IconContainer>
                            <Icon iconName={iconName} color={white} />
                        </IconContainer>
                    )}
                </ClickableCorner>
            )}

            <div
                css={button}
                style={{
                    // stylelint-disable-next-line string-no-newline
                    backgroundImage: `url("${
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        shapeColors[color] || shapeGrey
                    }")`,
                    ...(offsetLeft ? { left: String(offsetLeft) + "px" } : {}),
                }}
                className={classNames(
                    {
                        open: isOpen,
                        close:
                            hasInteractedWithComponent === true &&
                            isOpen === false,
                    },
                    position
                )}
                {...props}
            >
                {isOpen && (
                    <Close onClick={closeStopBubbling}>
                        <Icon iconName="close" cursor="pointer" />
                    </Close>
                )}
                <BottomFiller open={open} bgColor={colors[color]} />
                <Content>{children}</Content>
            </div>
        </>
    );
};

const BottomFiller = ({ open, bgColor }) => {
    if (open === "search") {
        return (
            <BottomRightFiller
                css={css`
                    background-color: ${bgColor};
                `}
            />
        );
    }
    if (open === "calculator") {
        return (
            <BottomLeftFiller
                css={css`
                    background-color: ${bgColor};
                `}
            />
        );
    }
    return null;
};

const BottomRightFiller = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 150px;
    border-top-left-radius: 62px;
    ${({ theme }) => theme.breakpoints.from.small.css`
        .left & {
            display: none;
        }

        .right & {
            left: 50%;
        }
    `}
`;

const BottomLeftFiller = styled.div`
    position: absolute;
    left: -15px;
    right: 0;
    bottom: -100px;
    height: 365px;
    width: 355px;
    transform: rotate(36deg);
    margin-left: 50px;
    background-repeat: none;
    ${({ theme }) => theme.breakpoints.from.small.css`
        .left & {
            display: none;
        }

        .right & {
            left: 50%;
        }
    `}
`;

ShapeButtonMobile.Content = styled.div`
    position: absolute;
    top: 55px;
    right: 150px;
    left: 105px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 30px;
    padding-left: 30px;
`;
ShapeButtonMobile.Content.displayName = "ShapeButtonMobile.Content";

ShapeButtonMobile.Headline = styled.div`
    position: absolute;
    right: 130px;
    left: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${closedShapeButtonHeight};
    font-size: 1.3rem;
    font-weight: 900;
    color: white;
`;
ShapeButtonMobile.Headline.displayName = "ShapeButtonMobile.Headline";

ShapeButtonMobile.ContentItem = styled.div`
    width: 100%;
`;
ShapeButtonMobile.ContentItem.displayName = "ShapeButtonMobile.ContentItem";

ShapeButtonMobile.positions = {
    left: "left",
    right: "right",
};

export default ShapeButtonMobile;
